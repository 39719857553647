<template>
  <nuxt-link to="/" aria-label="home">
    <picture class="logo">
      <source type="image/avif" :srcset="avif" />
      <source type="image/webp" :srcset="webp" />
      <img :src="src" alt="Home" width="280" height="84" />
    </picture>
  </nuxt-link>
</template>

<script setup>
const page = inject("page");
// const runtimeConfig = useRuntimeConfig();
const hostname = unref(page).hostname; // || runtimeConfig.public.hostname;
const prefix = process.env.NODE_ENV === "development" ? `https://${hostname}` : "";
const path = unref(page).logo || `/files/${hostname}/img/logo.png`;
const src = prefix + path;
const avif = src.replace(".png", ".avif");
const webp = src.replace(".png", ".webp");
</script>
